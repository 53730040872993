import React from 'react'
import { Helmet } from "react-helmet"

const SEO = ({titulo, descripcion, slug, imagen}) => {
    const _descripcion = 'Domain, tech you can rely on, desarrollo y consultoría';
    const _titulo = 'Domain México - Tech you can rely on';
    const _slug   = '';
    const _imagen = 'https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg';

    var miSlug = (slug)?slug:_slug;
    var miImagen = (imagen)? `${imagen}`:_imagen
    if (miImagen.startsWith('/')) // Si la imagen viene como URI, supongo que viene del sitio de Domain
        miImagen = `https://ww2.domainmx.mx${miImagen}`;
    return (
        <div>
            <Helmet 
            titleTemplate={`%s | ${_titulo}`}
            htmlAttributes={{ lang: `es` }}>
                <title>{titulo || _titulo}</title>
                <meta name="description" content={descripcion || _descripcion} />
                <link rel='canonical' href={`https://domainmx.mx/${miSlug}`} />
                <link rel='shortcut icon' href="https://domainmx.mx/favicon.ico" />
                <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:site' content='@domainmexico' />
                <meta name='twitter:creator' content='@_dariuus' />
                <meta name="twitter:title" content={titulo || _titulo}></meta>
                <meta name="twitter:image:src" content={miImagen}></meta>
                <meta name="og:image" content={miImagen}></meta>
                <meta name="og:description" content={descripcion || _descripcion} />
                <meta name="og:type" content="website"></meta>
                <meta name="og:url" href={`https://domainmx.mx/${miSlug}`} />
                <meta name="og:title" property="og:title" content={titulo || _titulo}></meta>
            </Helmet>
        </div>
    )
}

export default SEO
