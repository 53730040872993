import React from 'react'
import { RecoilRoot } from 'recoil'
import GoTop from './GoTop'
import SEO from './SEO'
import CookieConsent from "react-cookie-consent";

const Layout = ({ children, imagen, titulo, descripcion, slug }) => {
    return (
        <RecoilRoot>
            <SEO 
                titulo={titulo}
                descripcion={descripcion}
                slug={slug}
                imagen={imagen}
            />
            {children}
            <GoTop scrollStepInPx="100" delayInMs="10.50" />
            <CookieConsent
                enableDeclineButton
                flipButtons
                cookieName="gatsby-gdpr-google-analytics"
                buttonText="Entendido"
                declineButtonText="No acepto"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                declineButtonStyle={{  fontSize: "13px" }}
                acceptOnScroll={true}
                acceptOnScrollPercentage={20}
                onAccept={(byScroll) => {
                    // nada
                }}
            >Configuración de Cookies<br/><br/>
            <span style={{ fontSize: "10px" }}>
            Utilizamos cookies propias y de terceros para realizar análisis de uso y de medición de nuestra web, ofrecer una mejor funcionalidad y personalización así como para mostrarte publicidad relacionada con tus preferencias en base a un perfil elaborado a partir de tus hábitos de navegación. Consulta la Política de Cookies aquí para más información. Al hacer pulsar en “Aceptar”, permites que todas las cookies se guarden en tu terminal. También puedes configurarlas como prefieras o rechazarlas pulsando en "No acepto".
            </span>
            </CookieConsent>
        </RecoilRoot>
    )
}

export default Layout
